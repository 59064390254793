import jQuery from 'jquery';

// https://stackoverflow.com/questions/72114775/vite-global-is-not-defined
window.global ||= window;

window.process = {
  env: {
    RESET_APP_DATA_TIMER: false
  }
}

window.jQuery = jQuery